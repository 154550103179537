/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Link, Trans } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react';
import { constants } from '../../constants';
import LanguageSwitcher from '../widgets/langage-switcher/language-switcher';

export default class Navbar extends Component
{
  public state = {
    active: false,
  };

  public componentDidMount(): void
  {
    window.addEventListener('scroll', (e) => { this.handleScroll(e) });
  };

  public componentWillUnmount(): void
  {
      window.removeEventListener('scroll', this.handleScroll);
  };

  private handleScroll(event): void
  {
      if (window.scrollY <= 10 && this.state.active)
      {
        this.setState({ active: false });
      }
      else if (window.scrollY > 10 && !this.state.active)
      {
        this.setState({ active: true });
      }
  };

  public render(): React.ReactNode
  {
    return (
      <header className={`z-10 fixed inset-x-0 ${this.state.active ? 'bg-white shadow border-b-1 border-gray-100 text-gray-800' : 'bg-transparent text-white'}`}>
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
          <div className="w-full py-4 flex items-center justify-between border-none align-middle">

            <div className="flex items-center">
              <Link className="px-4 py-2" to="/">
                <p className="text-transparent text-xl font-extrabold bg-gradient-to-r from-emerald-300 via-sky-300 to-indigo-400 bg-clip-text">{ constants.app.name }</p>
              </Link>
              <div className="hidden ml-20 space-x-12 text-lg md:block align-middle">
                <Link to="/" className="font-bold hover:text-primary">
                  <Trans>Home</Trans>
                </Link>
                <Link to="/blog" className="font-bold hover:text-primary">
                  <Trans>Blog</Trans>
                </Link>
                <a href={ constants.links.contact } target="_blank" className="font-bold hover:text-primary">
                  <Trans>Contact</Trans>
                </a>
              </div>
            </div>
            <div className="ml-10 space-x-4">


            </div>
          </div>
        </nav>
      </header>
    )
  }
}
