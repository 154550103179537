/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Trans } from 'gatsby-plugin-react-i18next';
import React, { Component } from 'react';
import { constants } from '../../constants';

export default class Footer extends Component
{
  private navigation = {
    social: [
    ],
  }

  public render(): React.ReactNode
  {
    return (
      <footer className="mt-48 bg-slate-500" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="pb-8 xl:grid sm:grid-cols-4 sm:gap-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 xl:col-span-4">

              <div className="sm:mb-4">
                <h3 className="text-2xl mb-2 font-extrabold text-white">{ constants.app.name }</h3>
                <p className="text-slate-400">Boost your productivity</p>
              </div>

              <div className="col-span-3 grid sm:grid-cols-3 md:gap-8">
              <div className="">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Support</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
                      <a href={ constants.links.contact } target="_blank" className="text-base text-gray-300 hover:text-white">
                        <Trans>Contact</Trans>
                      </a>
                    </li>

                  </ul>
                </div>
                <div className="mt-8 sm:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
                      <a href="/blog" target="_blank" className="text-base text-gray-300 hover:text-white">
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="pt-8 mt-4 sm:pt-0 sm:mt-0 border-t border-gray-700 sm:border-none">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  <Trans>Subscribe to our newsletter</Trans>
                </h3>
                <p className="mt-4 text-base text-gray-300">
                  <Trans>Get AI learning resources.</Trans>
                </p>
                <a role="button" target="_blank" href="https://store.learn-gpt.com" className="mt-8
                w-full bg-indigo-600 font-bold border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500">
                      <Trans>Subscribe</Trans>
                </a>

            </div>
              </div>
            </div>
          </div>
          <div className="mt-4 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {this.navigation.social.map((item) => (
                <a key={item.name} href={item.href} target="_blank" className="text-gray-400 hover:text-gray-300">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
            <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
              &copy; { new Date().getFullYear() } { constants.app.name }. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
