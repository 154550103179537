/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

const siteDomain = 'tomorrow-knowledge.com';

export const constants = {
  app: {
    name: 'Tomorrow Knowledge',
  },
  links: {
    checkout: `https://store.${siteDomain}/checkout/buy/bd6d9304-4b9e-43e6-9775-322c60c4e9d3?discount=0`,
    contact: `https://support.${siteDomain}/support/tickets/new?ticket_form=ask_a_question`,
  },
  api: {
    newsletterRegister: `https://store.${siteDomain}/email-subscribe/external`
  }
}