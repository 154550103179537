/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import * as React from 'react';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SEO } from '../components/seo';
import NewsletterRegister from '../components/sections/newsletter-register';

const BlogPage = ({ data }) =>
{
  const { t } = useTranslation();
  const { edges: posts } = data?.allMdx || { edges: [] };

  return (
    <>
      <SEO
        title={t('Tomorrow Knowledge - The AI Blog')}
        description={t('This is the perfect source for anyone looking to learn about the latest advancements in AI technologies such as ChatGPT or Midjourney. From tutorials on getting started to in-depth articles about the newest trends, dive in with us and discover how to improve your daily work with AI.')}
      ></SEO>
      <Layout>

        <div className="py-20 sm:py-36 clip-path-curved bg-slate-900">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl"><Trans>Learn AI technologies</Trans></h2>
            <p className="hidden md:block mt-3 max-w-2xl mx-auto text-xl text-gray-400 sm:mt-4">
              <Trans>Learn how to master new AI technologies to gain a significant competitive advantage in your professional career</Trans>
            </p>
          </div>
        </div>
        <div className="relative bg-white pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">


          <NewsletterRegister />

          <div className="relative max-w-7xl mx-auto">

            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {posts
                .filter(post => post.node.frontmatter.title.length > 0)
                .map(({ node: post }) => (
                  <div key={post.id}>
                    <Link to={`/blog/${post.frontmatter.slug}`}>
                      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                        <div className="flex-shrink-0">
                          <GatsbyImage className="h-48 w-full object-cover" image={getImage(post.frontmatter.featuredImage)} alt="Featured image"></GatsbyImage>
                        </div>
                        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                          <div className="flex-1">
                            <div className="block mt-2">
                              <p className="text-xl font-semibold text-gray-900">{post.frontmatter.title}</p>
                              <p className="mt-3 text-base text-gray-500">{post.frontmatter.summary}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query($language: String!) {
    allMdx(filter: {frontmatter: { language: {eq: $language}, draft: {eq: false}}}) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            author
            title
            summary
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
  }

`
