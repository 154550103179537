/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { Component } from 'react';
import { constants } from '../../constants';

export default class NewsletterRegister extends Component
{
  public render(): React.ReactNode
  {
    return (

      <div className="bg-white text-center">
        <div className="mx-auto max-w-7xl py-12 px-6 lg:py-16 lg:px-8">
          <h2 className="inline text-3xl font-bold tracking-tight text-gray-800 sm:block sm:text-4xl">
            Develop new knowledge
          </h2>
          <p className="inline text-2xl font-bold tracking-tight font-bold bg-gradient-to-r from-emerald-500 via-sky-500 to-indigo-500 bg-clip-text  text-transparent font-display sm:block sm:text-3xl">
            Sign up for our newsletter.
          </p>
          <form className="mt-8 max-w-xl mx-auto sm:flex" action={ constants.api.newsletterRegister } method="post">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="w-full rounded-md border-gray-300 px-5 py-3 placeholder-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-md"
              placeholder="Enter your email"
            />
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                className="flex w-full items-center justify-center rounded-md border border-transparent px-5 py-3 text-base font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
